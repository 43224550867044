<template>
  <div class="articlelist">
    <!-- <div @click="create">11</div>
    <div class="share-wrap" ref="screenshot">
      <img
        :src="imageUrl + '?' + new Date().getTime()"
        crossOrigin="anonymous"
      />
    </div> -->
    <van-image-preview
      v-model="showprievewImage"
      :images="images"
      :show-index="false"
    >
    </van-image-preview>
    <canvas id="shareCanvas" ref="shareCanvas"></canvas>
    <div class="show-share-pic disfc" ref="showSharePic" v-if="imgUrl">
      <img id="img" :src="imgUrl" alt="img" style="width: 80%" />
      <p class="down disfr" v-if="platform === 'miniprogram'">
        长按图片发送给好友
      </p>
      <p class="down disfr" v-else>长按图片保存到手机</p>
      <i class="iconfont close" @click="closeSharePic">&#xe61d;</i>
    </div>
    <div id="article" v-if="!isPending" class="flexcolumnstart">
      <!-- 主标题 -->
      <p v-html="article.title" class="maintitle"></p>
      <!-- 作者 时间 -->
      <div class="flexrowbetween articleauthor">
        <span class="name">{{ article.authorName }}</span>
        <span class="data">{{ article.updatedAt }}</span>
      </div>
      <!-- 引言 -->
      <!-- <div v-if="article.author.brief" class="author-profile">
        {{ article.author.brief }}
      </div> -->
      <div
        class="content"
        ref="content"
        v-html="article.content"
        @click="showImg($event)"
      ></div>
       
      <div class="tool-bar disfr" v-if="operation" style="padding-bottom: 35px">
        <div class="disfc" @click="praiseAndCollect('praise')">
         
          <div
            class="flexrowstart"
            style="align-items: center"
            v-if="!article.hasPraised"
          >
            <img src="../../images/article/dianzan1.png" class="dianzan" />
            <div class="dzcontent">点赞</div>
          </div>
          <div
            class="flexrowstart"
            style="align-items: center"
            v-if="article.hasPraised"
          >
            <img src="../../images/article/dianzan2.png" class="dianzan" />
            <div class="dzcontent">已赞</div>
          </div>
          <!-- <span v-if="article.hasPraised">
            <label v-html="'已点赞 ' + article.praise"></label>
          </span>
          <span v-if="!article.hasPraised">
            <label v-html="'点赞 ' + article.praise"></label>
          </span> -->
        </div>
        <div class="disfc" @click="praiseAndCollect('collect')">
          <div
            class="flexrowstart"
            style="align-items: center"
            v-if="!article.hasCollected"
          >
            <img src="../../images/article/shoucang1.png" class="dianzan" />
            <div class="dzcontent">收藏</div>
          </div>
          <div
            class="flexrowstart"
            style="align-items: center"
            v-if="article.hasCollected"
          >
            <img src="../../images/article/shoucang2.png" class="dianzan" />
            <div class="dzcontent">已收藏</div>
          </div>
          <!-- <i class="iconfont" v-if="!article.hasCollected">&#xe68e;</i>
          <i class="iconfont" v-if="article.hasCollected">&#xe692;</i>
          <span v-if="article.hasCollected">
            <label v-html="'已收藏 ' + article.collection"></label>
          </span>
          <span v-if="!article.hasCollected">
            <label v-html="'收藏 ' + article.collection"></label>
          </span> -->
        </div>
        <div class="disfc" @click="drawShare" v-if="platform !== 'app'">
          <div class="flexrowstart" style="align-items: center">
            <img src="../../images/article/fenxiang.png" class="dianzan" />
            <div class="dzcontent">分享</div>
          </div>
          <!-- <i class="iconfont">&#xe68d;</i>
          <span>分享</span> -->
        </div>
      </div>
      <div
        class="tool-bar disfr"
        style="justify-content: center; padding-bottom: 35px"
        v-if="!operation && platform !== 'app'"
      >
        <div class="disfc" @click="drawShare">
          <div class="flexrowstart" style="align-items: center">
            <img src="../../images/article/fenxiang.png" class="dianzan" />
            <div class="dzcontent">分享</div>
          </div>
        </div>
      </div>
    </div>

    <img
      :src="`${article.thumb}?imageView2/1/w/450/h/360/q/100|imageslim`"
      class="img"
      crossorigin="anonymous"
      ref="banner"
      alt="banner"
    />
    <img
      :src="article.qrcode"
      crossorigin="anonymous"
      class="img"
      ref="code"
      alt="code"
    />
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import CONFIG from "@/config";
let IMG_BANNER = "";
let IMG_CODE = "";
let IMG_BANNER_WIDTH = 0;
let IMG_BANNER_HEIGHT = 0;
let scale = 0;
let ratio = 0;
let canvasDrawFontsInOneLine = 0;
const titleFontSize = 18;
export default {
  name: "articles",
  data() {
    return {
      imgUri: "",
      images: [],
      showprievewImage: false,
      qnImgUrl: CONFIG.qnImgUrl,
      imgUrl: "",
      isPending: true,
      article: {},
      // 是不是需要点赞和收藏 true 表示显示按钮，其他表示不显示
      operation: this.$route.query.operation,
      // platform为ios或android不显示分享参数为platform=app
      platform: this.$route.query.platform,
      // 是否可以分享
      isCanShare: false,
      imageUrl: "https://y.shulan.com/article_qrcode/96",
      screenshotImage: "", // 保存的base64地址
    };
  },
  mounted() {
    window.ontouchstart = function (e) {
      e.preventDefault();
    };
    if (this.$route.query.token) {
      localStorage.setItem("Token", this.$route.query.token);
    }
    this.getArticle();
    this.shareHandle();
  },
  methods: {
    async shareHandle() {
      const opts = {
        useCORS: true,
      };
      const ele = this.$refs.screenshot;
      const canvas = await html2canvas(this.$refs.screenshot, opts);
      this.screenshotImage = canvas.toDataURL("image/jpg");
      console.log(this.screenshotImage);
    },

    //生成画布
    create() {
      html2canvas(this.$refs.screenshot).then((canvas) => {
        let dataURL = canvas.toDataURL("image/png");
        this.imgUri = dataURL;
      });
      // let self = this;
      // event.preventDefault();
      // let canvas2 = document.createElement("canvas");
      // let _canvas = document.getElementById("canvasPic");
      // let w = parseInt(window.getComputedStyle(_canvas).width);
      // let h = parseInt(window.getComputedStyle(_canvas).height);
      // //放大canvas画布，然后放在较小的容器内，像素会升高
      // canvas2.width = w * 2;
      // canvas2.height = h * 2;
      // canvas2.style.width = w + "px";
      // canvas2.style.height = h + "px";
      // let context = canvas2.getContext("2d");
      // context.scale(2, 2);
      // html2canvas(document.getElementById("canvasPic"), {
      //   canvas: canvas2,
      // }).then((canvas) => {
      //   let fileName = "图片名字" + ".jpg";
      //   let imgUris = canvas.toDataURL("image/jpeg", 2); // 获取生成的图片的url
      //   console.log(imgUris);
      //   this.imgUri = imgUris;
      // });
    },
    //   图片放大
    showImg(e) {
      if (e.target.tagName == "IMG") {
        console.log(e.target.src);
        let arr = [e.target.src];
        this.images = arr;
        this.showprievewImage = true;
      }
    },
    replacecommonstyle(html) {
      let content = html;
      content = content.replace(/ style="(.*?)"/gi, ""); //style过滤
      return content;
    },
    // 文章详情shulanwebapi
    getArticle() {
      this.$axios
        .get(
          `/information/articles/byId?id=${this.$route.query.id}`,
          { basurlType: "commonurl" }
        )
        .then((res) => {
          //文章内容统一过滤 style
          this.article = res.data;
          // this.article.content = this.replacecommonstyle(res.data.data.content);
          document.title = res.data.title;
          this.isPending = false;
          // this._calcData();
          // this._jssdk();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 点赞于收藏
    praiseAndCollect(type) {
      const platform = this.$route.query.platform;
      let url = type == 'praise' ? `/information/articles/like?id=${this.$route.query.id}` : `/information/articles/collect?id=${this.$route.query.id}`
       this.$axios
        .post(
           url, { basurlType: "commonurl" }
        )
        .then((res) => {
          if (res) {
            switch (type) {
              case "praise":
                this.article.praise = this.article.praise + 1;
                this.article.hasPraised = true;
                break;
              case "collect":
                this.article.collection = this.article.collection + 1;
                this.article.hasCollected = true;
                break;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    drawShare() {
      if (!this.isCanShare) {
        this.$toast.fail("分享数据初始化，请稍等~");
        return;
      }
      canvasDrawFontsInOneLine = Math.floor(
        (this.$refs.shareCanvas.clientWidth - 20) / titleFontSize
      );
      if (this.article.title.length > canvasDrawFontsInOneLine) {
        this.drawShare1();
      } else {
        this.drawShare2();
      }
    },
    // 绘制分享图
    drawShare1() {
      const sym =
        this.article.title.length > 2 * canvasDrawFontsInOneLine - 5
          ? "..."
          : "";
      const canvas = this.$refs.shareCanvas;
      const ctx = canvas.getContext("2d");
      const cw = this.$refs.shareCanvas.clientWidth;
      const ch = this.$refs.shareCanvas.clientHeight;
      ctx.clearRect(0, 0, cw * ratio, ch * ratio);
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, cw * ratio, ch * ratio);
      ctx.drawImage(
        IMG_BANNER,
        0,
        0,
        IMG_BANNER_WIDTH * scale * ratio,
        IMG_BANNER_HEIGHT * scale * ratio
      );
      ctx.fillStyle = "#272929";
      ctx.font = `${
        titleFontSize * ratio
      }px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
      ctx.fillText(
        this.article.title.substring(0, canvasDrawFontsInOneLine),
        10 * ratio,
        IMG_BANNER_HEIGHT * scale * ratio + 30 * ratio,
        (IMG_BANNER_WIDTH - 10) * ratio
      );
      ctx.fillStyle = "#272929";
      ctx.font = `${
        titleFontSize * ratio
      }px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
      ctx.fillText(
        this.article.title.substring(
          canvasDrawFontsInOneLine,
          2 * canvasDrawFontsInOneLine - 5
        ) + sym,
        10 * ratio,
        IMG_BANNER_HEIGHT * scale * ratio + 52 * ratio,
        (IMG_BANNER_WIDTH - 10) * ratio
      );
      ctx.fillStyle = "#9A9E9E";
      ctx.font = `${
        12 * ratio
      }px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
      ctx.fillText(
        this.article.created_at.substring(0, 10).split("-").join("/"),
        10 * ratio,
        IMG_BANNER_HEIGHT * scale * ratio + 70 * ratio,
        (IMG_BANNER_WIDTH - 10) * ratio
      );
      ctx.fillStyle = "#272929";
      ctx.font = `${
        14 * ratio
      }px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
      ctx.fillText(
        "长按识别二维码",
        10 * ratio,
        (ch - 35) * ratio,
        (IMG_BANNER_WIDTH - 10) * ratio
      );
      ctx.fillStyle = "#9A9E9E";
      ctx.font = `${
        12 * ratio
      }px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
      ctx.fillText(
        "树兰医生 触手可及的名医专家",
        10 * ratio,
        (ch - 15) * ratio,
        (IMG_BANNER_WIDTH - 10) * ratio
      );
      ctx.drawImage(
        IMG_CODE,
        0,
        0,
        IMG_CODE.width,
        IMG_CODE.height,
        (IMG_BANNER_WIDTH * scale - 100) * ratio,
        (ch - 100) * ratio,
        90 * ratio,
        90 * ratio
      );
      const src = canvas.toDataURL("image/jpg");
      this.imgUrl = src;
    },
    drawShare2() {
      const canvas = this.$refs.shareCanvas;
      const ctx = canvas.getContext("2d");
      const cw = this.$refs.shareCanvas.clientWidth;
      const ch = this.$refs.shareCanvas.clientHeight;
      ctx.clearRect(0, 0, cw * ratio, ch * ratio);
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, cw * ratio, ch * ratio);
      ctx.drawImage(
        IMG_BANNER,
        0,
        0,
        IMG_BANNER_WIDTH * scale * ratio,
        IMG_BANNER_HEIGHT * scale * ratio
      );
      ctx.fillStyle = "#272929";
      ctx.font = `${
        titleFontSize * ratio
      }px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
      ctx.fillText(
        this.article.title,
        10 * ratio,
        IMG_BANNER_HEIGHT * scale * ratio + 30 * ratio,
        (IMG_BANNER_WIDTH - 10) * ratio
      );
      ctx.fillStyle = "#9A9E9E";
      ctx.font = `${
        12 * ratio
      }px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
      ctx.fillText(
        this.article.created_at.substring(0, 10).split("-").join("/"),
        10 * ratio,
        IMG_BANNER_HEIGHT * scale * ratio + 50 * ratio,
        (IMG_BANNER_WIDTH - 10) * ratio
      );
      ctx.fillStyle = "#272929";
      ctx.font = `${
        14 * ratio
      }px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
      ctx.fillText(
        "长按识别二维码",
        10 * ratio,
        (ch - 35) * ratio,
        (IMG_BANNER_WIDTH - 10) * ratio
      );
      ctx.fillStyle = "#9A9E9E";
      ctx.font = `${
        12 * ratio
      }px PingFang SC, Microsoft YaHei, Helvetica Neue, Helvetica, Arial`;
      ctx.fillText(
        "树兰医生 触手可及的名医专家",
        10 * ratio,
        (ch - 15) * ratio,
        (IMG_BANNER_WIDTH - 10) * ratio
      );
      ctx.drawImage(
        IMG_CODE,
        0,
        0,
        IMG_CODE.width,
        IMG_CODE.height,
        (IMG_BANNER_WIDTH * scale - 100) * ratio,
        (ch - 100) * ratio,
        90 * ratio,
        90 * ratio
      );
      const src = canvas.toDataURL("image/jpg");
      this.imgUrl = src;
    },
    closeSharePic() {
      this.imgUrl = "";
    },
    _jssdk() {
      if (process.env.NODE_ENV !== "production") return;
      const _self = this;
      this.$HTTP
        .post({
          url: "/jssdk",
          params: { fullUrl: decodeURIComponent(window.location.href) },
        })
        .then((res) => {
          wx.config({
            debug: res.data.debug,
            appId: res.data.appId,
            timestamp: res.data.timestamp * 1,
            nonceStr: res.data.nonceStr,
            signature: res.data.signature,
            jsApiList: res.data.jsApiList,
          });
          wx.ready(function () {
            wx.updateAppMessageShareData({
              title: _self.article.title,
              desc: _self.article.description,
              link: _self.article.url,
              imgUrl: `${_self.article.thumb}?imageView2/1/w/120/h/120/q/100|imageslim`,
              success: function () {},
            });
            wx.updateTimelineShareData({
              title: _self.article.title,
              link: _self.article.url,
              imgUrl: `${_self.article.thumb}?imageView2/1/w/120/h/120/q/100|imageslim`,
              success: function () {},
            });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 计算一些数据
    _calcData() {
      setTimeout(() => {
        IMG_BANNER = this.$refs.banner;
        IMG_BANNER_WIDTH = this.$refs.banner.clientWidth;
        IMG_BANNER_HEIGHT = this.$refs.banner.clientHeight;
        IMG_CODE = this.$refs.code;
        const canvas = this.$refs.shareCanvas;
        const ctx = canvas.getContext("2d");
        scale =
          window.innerWidth > IMG_BANNER_WIDTH
            ? IMG_BANNER_WIDTH / (window.innerWidth - 100)
            : (window.innerWidth - 100) / IMG_BANNER_WIDTH;
        ratio = this._getPixelRatio(ctx);
        canvas.style.width = `${IMG_BANNER_WIDTH * scale}px`;
        canvas.style.height = `${IMG_BANNER_HEIGHT * scale + 172}px`;
        canvas.width = IMG_BANNER_WIDTH * scale * ratio;
        canvas.height = IMG_BANNER_HEIGHT * scale * ratio + 172 * ratio;
        this.isCanShare = true;
        this._resizeImg();
      }, 1000);
    },
    // 图片设置100%
    _resizeImg() {
      const imgs = this.$refs.content.getElementsByTagName("img");
      for (let i = 0; i < imgs.length; i++) {
        imgs[i].style.maxWidth = this.$refs.content.clientWidth + "px";
      }
    },
    // 高清屏幕模糊设置
    _getPixelRatio(context) {
      const backingStore =
        context.backingStorePixelRatio ||
        context.webkitBackingStorePixelRatio ||
        context.mozBackingStorePixelRatio ||
        context.msBackingStorePixelRatio ||
        context.oBackingStorePixelRatio ||
        context.backingStorePixelRatio ||
        1;
      return (window.devicePixelRatio || 1) / backingStore;
    },
  },
};
</script>

<style lang="scss">
.articlelist {
  font-weight: normal;
  * {
    -webkit-touch-callout: none;
  }
  #article {
    background-color: #fff;
    padding: 0 40px;
  }
  .maintitle {
    font-size: 48px;
    font-family: PingFang-SC-Bold, PingFang-SC;
    font-weight: bold;
    color: #2a2c32;
    margin-top: 30px;
  }
  .articleauthor {
    align-items: center;
    margin-top: 30px;
    .name {
      font-size: 26px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #2a2c32;
    }
    .data {
      font-size: 26px;
      font-family: PingFang-SC-Medium, PingFang-SC;
      font-weight: 500;
      color: #9c9fad;
    }
  }
  .author-profile {
    font-size: 30px;
    font-family: PingFang-SC-Regular, PingFang-SC;
    font-weight: 400;
    color: #2a2c32;
    margin-top: 60px;
  }
  .dianzan {
    width: 44px;
    height: 44px;
  }
  .dzcontent {
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-left: 10px;
  }
  #article .author {
    margin: 20px 0 0;
    justify-content: space-between;
    align-items: center;
  }
  #article .author .left {
    align-items: center;
  }
  #article .author .avatar {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    margin-right: 20px;
    font-size: 24px;
  }
  #article .author .name {
    font-size: 28px;
    color: #272929;
    font-weight: 500;
  }
  #article .author .right {
    color: #18b09f;
    font-size: 24px;
    font-weight: 500;
    align-items: center;
  }
  #article .author .right i {
    font-size: 28px;
    margin-left: 12px;
  }
  #article .content {
    padding-bottom: 180px;
  }
  #article .content {
    zoom: 1 !important;
  }
  #article .content:after {
    content: "" !important;
    display: block !important;
    clear: both !important;
  }
  #article .content >>> * {
    max-width: 100% !important;
    box-sizing: border-box !important;
    -webkit-box-sizing: border-box !important;
    word-wrap: break-word !important;
  }
  #article .tool-bar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: space-between;
    text-align: center;
    background-color: #fff;
    border-top: 2px solid #f1f1f1;
    padding: 0 100px;
    height: 96px;
    align-items: center;
    z-index: 9;
  }
  #article .tool-bar i {
    font-size: 36px;
    color: #18b09f;
    margin-bottom: 8px;
    font-weight: 500;
  }
  #article .tool-bar span {
    font-size: 20px;
    color: #18b09f;
  }

  #shareCanvas {
    position: absolute;
    top: -9999px;
    display: block;
    margin: 0 auto;
  }
  .show-share-pic {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999999999999999;
  }
  .show-share-pic .down {
    font-size: 32px;
    margin-top: 32px;
    font-weight: 500;
    color: #fff;
    /*background-color: #18B09F;*/
    /*width: 188px;*/
    /*height: 44px;*/
    border-radius: 6px;
    align-items: center;
    justify-content: center;
  }
  .show-share-pic .down i {
    margin-right: 12px;
  }
  .show-share-pic .close {
    font-size: 32px;
    color: #fff;
    font-weight: 500;
    margin-top: 32px;
  }
  .img {
    position: absolute;
    top: -999999px;
  }
  #article .content >>> p[data-f-id="pbf"] {
    display: none !important;
  }
  #article .content ul li {
    list-style: initial !important;
  }
  #article .content ul,
  #article .content ol {
    margin-left: 50px !important;
    list-style: initial !important;
  }
  .content img {
    width: 100% !important;
    // margin-top: 30px;
    border-radius: 10px;
    height: auto;
  }
  #article video {
    width: 100% !important;
    margin: 20px 0;
  }
  //文章内容样式
  //内容-标题
  .slContentTitle {
    font-size: 32px;
    font-family: "PingFang SC";
    font-weight: bold;
    color: #2a2c32;
  }
  //注释
  .slAnnotation {
    text-align: justify;
    margin-top: 30px;
    font-size: 28px;
    font-family: "PingFang SC";
    font-weight: 400;
    color: #717381;
    line-height: 80px;
  }
  //一级标题
  .slFirstTitle {
    text-align: justify;
    font-size: 42px;
    font-family: "PingFang SC";
    font-weight: bold;
    color: #2a2c32;
    margin-top: 50px;
  }
  //二级标题
  .slSecondTitle {
    text-align: justify;
    font-size: 36px;
    font-family: "PingFang SC";
    font-weight: bold;
    color: #2a2c32;
    margin-top: 50px;
  }
  //三级标题
  .slThreeTitle {
    text-align: justify;
    font-size: 32px;
    font-family: "PingFang SC";
    font-weight: bold;
    color: #2a2c32;
    margin-top: 50px;
  }
  //内容
  .slContent {
    text-align: justify;
    font-size: 32px;
    margin-top: 30px;
    font-family: "PingFang SC";
    font-weight: 300;
    color: #2a2c32;
    line-height: 50px;
  }
  //引言
  .slIntroduction {
    text-align: justify;
    padding-left: 20px;
    border-left: 4px solid #b6b6b6;
    font-size: 30px;
    font-family: "PingFang SC";
    font-weight: 400;
    color: #777777;
    line-height: 50px;
    margin-top: 60px;
  }
  //图片说明
  .slImgIntroduce {
    font-size: 28px;
    font-family: "PingFang SC";
    font-weight: 400;
    color: #717381;
    margin-top: 20px;
    text-align: left;
  }
  //责任编辑
  .slPeople {
    text-align: justify;
    font-size: 26px;
    font-family: "PingFang SC";
    font-weight: 400;
    color: #67676a;
    line-height: 50px;
    margin-top: 50px;
  }

  //参考资料
  .slReference {
    text-align: justify;
    font-size: 30px;
    font-family: "PingFang SC";
    font-weight: bold;
    color: #717381;
    margin-top: 80px;
    margin-bottom: 30px;
  }
  //参考详情
  .slDetail {
    text-align: justify;
    font-size: 26px;
    font-family: "PingFang SC";
    font-weight: 400;
    color: #67676a;
    line-height: 50px;
  }
  //文章链接
  .slReference3 {
    text-align: justify;
    font-size: 24px;
    font-family: "PingFang SC";
    font-weight: 400;
    color: #9294a1;
    line-height: 34px;
    margin-bottom: 10px;
  }
  //文章背景
  .slArticlefrom {
    padding: 24px 24px 14px;
    background: #f8f9fb;
    border-radius: 8px;
    margin-top: 40px;
  }
}
</style>
